import React from "react"
import { graphql, Link } from "gatsby"
import Loadable from "@loadable/component"
import { Helmet } from "react-helmet"

import FAQ from "../components/FAQ"
import Layout from "../components/layouts/Layout"
import Button from "../components/Button"
import Img from "gatsby-image"
import pMinDelay from 'p-min-delay'

const QRGenerator = Loadable(() => pMinDelay(import("../components/QrGenerator"), 500),{
  fallback: <div style={{height:253+'px', paddingTop: 80+'px'}}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style={{margin: 'auto auto', height: 50+'px'}}>
      <path opacity=".5" d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"/>
      <path style={{fill: '#ffffff'}} d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
        <animateTransform attributeName="transform" type="rotate" from="0 16 16" to="360 16 16" dur="0.8s" repeatCount="indefinite" />
      </path>
    </svg>
  </div>
})

export default ({data}) => 
  <Layout showSubFooter={false} splash={true}>
    <Helmet>
      <title>QR Code Generator</title>
      <meta name="description" content="Free QR Code Generator with dynamic links, redirection rules and scan analytics." />
      <meta property="og:description" content="Free QR Code Generator with dynamic links, redirection rules and scan analytics." />
      <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "How do I create / generate a QR code?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "You can create a QR code with the CounterSet QR code generator. The CounterSet QR code generator is free to use and creates QR codes that conform to the current QR code standard. CounterSet generates QR codes that can resolve to a URL, call a phone number, compose an email or connect to a WiFi network. If you want to obtain consumer insights from your QR codes, CounterSet provides a paid platform that provides additional features and full analytics."
              }
            }, {
              "@type": "Question",
              "name": "What are QR codes used for?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "QR codes are used for a multitude of purposes that are increasing in novelty and number by the day. For example, QR codes can be linked to videos, picture galleries, social media or web pages.
                  A QR code can also be uniquely serialised to an individual product item or be linked to a batch or SKU, to track products throughout the supply chain.
                  A QR code can also be used to join a WIFI network, making it easy for people to scan the code and join the network securely.
                "
              }
            }, {
              "@type": "Question",
              "name": "Are QR codes free?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes. QR codes are free in the sense that you are free to scan them and free to create them. CounterSet offers a free QR code creation tool that allows you to create QR codes that link to directly to websites, phone numbers, social media or show locations and allow you to quickly join a wifi network. If you require analytics to get insights flowing from your QR codes, CounterSet provides a paid platform that provides additional features and full analytics."
              }
            }, {
              "@type": "Question",
              "name": "How do I scan a QR code with my phone?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "A QR code can be scanned with an app or with inbuilt camera phone functionality (iPhone). If you have an iPhone running iOS 11 or later, you can open your phone's native camera and when you view the QR code, a modal will appear with a prompt."
              }
            }]
          }
      `}</script>
    </Helmet>
    <div className="bg-gray-900 my-0">
      <div className="w-full max-w-screen-xl mx-auto px-6 flex justify-center content-center flex-col">
          <div className="pt-12 text-center">
              <h1 className="text-4xl text-white">QR Code Creator</h1>
              <p className="text-lg text-gray-300 font-normal">Create your QR Code for free</p>
          </div>
          <QRGenerator></QRGenerator>
      </div>
    </div>

    <div className="p1 bg-blue-100">
      <div className="max-w-screen-xl mx-auto px-6 py-6">
        <div className="flex flex-row flex-wrap my-4">
          <div className="w-full py-6 pr-4 sm:py-4">
            <div className="font-bold text-2xl mb-2">Want more advanced features?</div>

            <p className="mb-8">
              Our dynamic link engine lets you:
              <ul className="tick-list">
                <li>Change QR scan destination at any time</li>
                <li>Track scans and customer analytics</li>
                <li>Dynamically redirect based on customer location, locale and language</li>
              </ul>
            </p>
            <p className="mb-4">
              Sign up for free and get 5 free dynamic QR Codes.
            </p>
            <a href="https://dashboard.counterset.com/#/signup" className="items-center btn btn-black">Get Started</a>
          </div>
        </div>
      </div>
    </div> 

    <div className="w-full max-w-screen-xl mx-auto px-6 pt-6">
      <FAQ answers={[
      {
        q: 'How do I create / generate a QR code?',
        a: (<div><p className="mb-4">
            You can create a QR code with the CounterSet QR code generator. The CounterSet QR code generator is free to use and creates QR codes that conform to the current QR code standard.
          </p><p className="mb-4">
            CounterSet generates QR codes that can resolve to a URL, call a phone number, compose an email or connect to a WiFi network.
          </p><p className="mb-4">
            If you want to obtain consumer insights from your QR codes, CounterSet provides a paid platform that provides additional features and full analytics.
          </p></div>)
      }, {
        q: 'What are QR codes used for?',
        a: (<div><p className="mb-4">
              QR codes are used for a multitude of purposes that are increasing in novelty and number by the day. For example, QR codes can be linked to videos, picture galleries, social media or web pages.
            </p>
            <p className="mb-4">
              A QR code can also be uniquely serialised to an individual product item or be linked to a batch or SKU, to track products throughout the supply chain.
            </p><p className="mb-4">
              A QR code can also be used to join a WIFI network, making it easy for people to scan the code and join the network securely.
            </p>
          </div>)
      }, {
        q: 'Are QR codes free?',
        a: (<div><p className="mb-4">
            Yes. QR codes are free in the sense that you are free to scan them and free to create them.
          </p><p className="mb-4">
            CounterSet offers a free QR code creation tool that allows you to create QR codes that link to directly to websites, phone numbers, social media or show locations and allow you to quickly join a wifi network.
          </p><p className="mb-4">
            If you require analytics to get insights flowing from your QR codes, CounterSet provides a paid platform that provides additional features and full analytics.
          </p>
        </div>)
      },
      {
        q: 'How do I scan a QR code with my phone?',
        a: (<div><p className="mb-4">
          A QR code can be scanned with an app or with inbuilt camera phone functionality (iPhone).
        </p><p className="mb-4">
          If you have an iPhone running iOS 11 or later, you can open your phone's native camera and when you view the QR code, a modal will appear with a prompt.
        </p><p className="mb-4">
        </p></div>)
      }
      ]}></FAQ>
    </div>
    
  </Layout>

//export const query = graphql`
//`